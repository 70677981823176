import React from "react";
import "./data-deletion-instruction.scss";
import { Card, Col, Row } from "antd";
import Divider from "../../images/aboutus-divider.png";
import { dataDeletionInstructionSentences as p } from "./ArrDataDeletionInstruction";
import { useTranslation } from "react-i18next";
function DataDeletionInstruction() {
  const { t } = useTranslation();

  return (
    <div className="data-deletion-instruction-container">
      <Row justify="center">
        <Col xl={16} sm={20} xs={23} className="data-deletion-instruction-title">
          <Row justify="center">
            <Col>
                <div>{p.find(x=>x.phase===0).description}</div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===1).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>1. {p.find(x=>x.phase===2).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>2. {p.find(x=>x.phase===3).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===4).description}</p>
            </Col>
          </Row>
          <br></br>
        </Col>
      </Row>
    </div>
  );
}

export default DataDeletionInstruction;
