import React from "react";
import "./privacy-policy.scss";
import { Card, Col, Row } from "antd";
import Divider from "../../images/aboutus-divider.png";
import { privacyPolicySentences as p } from "./ArrPrivacyPolicy";
import { useTranslation } from "react-i18next";
function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-container">
      <Row justify="center">
        <Col xl={16} sm={20} xs={23} className="privacy-policy-title">
          <Row justify="center">
            <Col>
                <div>{p.find(x=>x.phase===0).description}</div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===1).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===2).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===3).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===4).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===5).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===6).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row justify="center">
            <Col>
                <div>{p.find(x=>x.phase===7).description}</div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===8).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>1. {p.find(x=>x.phase===9).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>2. {p.find(x=>x.phase===10).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>3. {p.find(x=>x.phase===11).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>4. {p.find(x=>x.phase===12).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>5. {p.find(x=>x.phase===13).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>6. {p.find(x=>x.phase===14).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>7. {p.find(x=>x.phase===15).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>8. {p.find(x=>x.phase===16).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>9. {p.find(x=>x.phase===17).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===18).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===19).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===20).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===21).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row justify="center">
            <Col>
                <div>{p.find(x=>x.phase===22).description}</div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===23).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row justify="center">
            <Col>
                <div>{p.find(x=>x.phase===24).description}</div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===25).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===26).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row justify="center">
            <Col>
                <div>{p.find(x=>x.phase===27).description}</div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===28).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>- {p.find(x=>x.phase===29).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>- {p.find(x=>x.phase===30).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>- {p.find(x=>x.phase===31).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>- {p.find(x=>x.phase===32).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>- {p.find(x=>x.phase===33).description}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>- {p.find(x=>x.phase===34).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>- {p.find(x=>x.phase===35).description}</p>
            </Col>
          </Row>
          <br></br>
          <Row justify="center">
            <Col>
                <div>{p.find(x=>x.phase===36).description}</div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <p>{p.find(x=>x.phase===37).description}</p>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
        </Col>
      </Row>
    </div>
  );
}

export default PrivacyPolicy;
