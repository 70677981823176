export const privacyPolicySentences = [
  {
    phase: 0,
    description: "Privacy Policy"
  },
  {
    phase: 1,
    description: "Antsloan is committed to keeping your personal information secure and protected, and this Privacy Policy explains the reasons behind the necessity for personal information and how we collect, use, disclose, and secure it. This Privacy Policy is applicable to the personal information collected both online and offline from you and third parties. However, it does not extend to any personal information collected by third parties from you."
  },
  {
    phase: 2,
    description: "By accessing or using the Website and Mobile Application, you indicate your acknowledgment, understanding, and agreement to be bound by this Privacy Policy. We reserve the right to update or modify this Privacy Policy without prior notice, and we encourage you to review it each time you access our Services. Material changes may be notified through email or notices on our home page."
  },
  {
    phase: 3,
    description: "The following terms and definitions have been set out in this privacy policy:"
  },
  {
    phase: 4,
    description: "“Antsloan”, “We”, “Us” which owns and operates the website, https://antsloan.co/ (“Website and Mobile Application”)"
  },
  {
    phase: 5,
    description: "“You” and “Your” means a user of the website and the products and services provided via the website and Mobile Application."
  },
  {
    phase: 6,
    description: "“Services” includes lending and any other related services by us via our Website and Mobile Application."
  },
  {
    phase: 7,
    description: "Collection of User Information"
  },
  {
    phase: 8,
    description: "To create an account on Antsloan, you must provide us with the basic details and information required as part of our Customer Identification process and you agree to this Privacy Policy, which governs how we treat your information. During the use of our Services, Website and Mobile Application and App, we may collect information about you from you and from third parties. While some information must be mandatorily provided, others are optional and certain portions of the information will remain private and some will be shared with associated third parties. All information you provide to us is voluntary. This information is including but not limited to the following:"
  },
  {
    phase: 9,
    description: "Information about you when you register or when you use our Website and Mobile Application."
  },
  {
    phase: 10,
    description: "Personal and financial information like your name, email address, contact number, gender, date of birth, contact information, address, etc."
  },
  {
    phase: 11,
    description: "Information about your transaction history like spend data, through our app etc."
  },
  {
    phase: 12,
    description: "We may also collect other personal information such as SMS, contacts, location data, mobile device data, images and videos etc."
  },
  {
    phase: 13,
    description: "Your unique identifiers such as username and password, preferences information, and transaction history."
  },
  {
    phase: 14,
    description: "Information collected through your Facebook, Twitter, LinkedIn, or other social media accounts which have been linked to the account on the Website and Mobile Application."
  },
  {
    phase: 15,
    description: "Information that you provide when you write directly to us (including by email) or provide us over telephone."
  },
  {
    phase: 16,
    description: "Any other information that Antsloan is required to collect as per specific mandate from any bank or as a requirement under applicable law."
  },
  {
    phase: 17,
    description: "Aggregate information and electronic data – like the pages viewed, how you navigate through the website and interact with web pages, etc."
  },
  {
    phase: 18,
    description: "By registering for a Antsloan account or beginning an application on the Antsloan website or mobile application for any product or service, you are consenting to be contacted by us by written notices, email messages, text messages, WhatsApp, or telephone, and, in connection with any such telephone calls, you consent to the use pre-recorded/artificial voice messages and/or automatic dialing devices, at any telephone number associated with your Antsloan account, including mobile telephone numbers or at any email address or mailing address we have for you in our records or from other public and nonpublic databases we may lawfully access. You agree that Antsloan may contact you using the information you provided with information and offers of services available through Antsloan website and mobile application."
  },
  {
    phase: 19,
    description: "The information we collect about you will depend on the products and services we offer, on an ongoing basis. If you do not allow us to collect all the information we request, we may not be able to deliver all these services effectively."
  },
  {
    phase: 20,
    description: "You understand that, by creating an account or by registering through third party sites, we andothers will be able to identify you by your Antsloan profile. However, we will not be liable underany circumstance for the generation of your profile or the information you provide therein. Antsloan will also not be liable for any information collected in the form of SMS, contact, media, or any other sources that the users might upload, the data collection process may extract, the use and misuse thereof."
  },
  {
    phase: 21,
    description: "All the information that you shall provide us is voluntary, including sensitive personal information. By creating an account, you acknowledge that your profile can be identified, but Antsloan is not liable for the generation of your profile or the information provided. Sensitive personal information may be used for service provision and shared with affiliates under this Privacy Policy."
  },
  {
    phase: 22,
    description: "Third Party Sharing of Your Information"
  },
  {
    phase: 23,
    description: "We will take utmost care to ensure that your personal information is not shared with the public and that your transactions on the Website and Mobile Application are confidential. If we use any third parties for any of these transactions, we will also make sure that confidentiality is maintained with these authorized third parties. We only share your personal information with affiliated third parties as permitted or required by law. When we share your personal information with vendors and service providers who perform functions on our behalf, we require that they keep your information confidential and secure and that they use it only as is reasonably necessary to carry out their work or comply with applicable law. We may share your personal information with affiliates and as part of joint marketing campaigns with other financial companies. In addition, personal information may be disclosed or transferred to our affiliates and as part of, or during negotiations of, a merger, consolidation, sale of company assets or equity, financing, acquisition, strategic alliance or in any other situation where personal information may be transferred as one of the business assets of Antsloan."
  },
  {
    phase: 24,
    description: "Protecting Your Information"
  },
  {
    phase: 25,
    description: "Antsloan emphasizes the confidentiality of user information and ensures secure transactions on the Website and Mobile Application. Personal information may be shared with affiliated third parties, vendors, and service providers as permitted by law. The Privacy Policy details administrative, technical, and physical safeguards to protect personal information, including security measures like firewalls and data encryption."
  },
  {
    phase: 26,
    description: "However, you understand and accept that there is no guarantee that data transmission over the Internet will be completely secure and that any information that you transmit to us is at your own risk. We assume no liability for any disclosure of information due to errors in transmission, unauthorized third-party access to our Website and Mobile Application and databases or other acts of third parties or acts or omissions beyond our reasonable control and you shall not be entitled to hold Antsloan responsible for any breach of security."
  },
  {
    phase: 27,
    description: "Tracking, Use of Cookies, Web Beacons and Similar Tracking Devices"
  },
  {
    phase: 28,
    description: "Cookies are small data files that a website stores on your computer. Antsloan uses cookies for security and to personalize your web browsing experience. We may use cookies for some situations, such as"
  },
  {
    phase: 29,
    description: "To allocate an identification number to your Internet browser (you cannot be identified from this number)."
  },
  {
    phase: 30,
    description: "To make our Website and Mobile Application more user-friendly."
  },
  {
    phase: 31,
    description: "To determine if you have previously visited the website."
  },
  {
    phase: 32,
    description: "To track and report on website and online campaign performance."
  },
  {
    phase: 33,
    description: "To identify other pages or third-party websites you have accessed."
  },
  {
    phase: 34,
    description: "To detect fraud and for security purposes."
  },
  {
    phase: 35,
    description: "It is possible for you to configure your browser so that it does not receive cookies. However, this means you will be unable to use secure services that require cookies to participate. Some of those services include (but are not limited to) our online application and some member features."
  },
  {
    phase: 36,
    description: "Contacting Antsloan"
  },
  {
    phase: 37,
    description: "For questions or concerns about the Privacy Policy or noncompliance, users can contact Antsloan through the provided email (antloans11@gmail.com) or contact number (+60165002756), detailing the perceived violations without disclosing sensitive information."
  },
];
